<template>
	<b-container fluid>
		<b-row>
			<Breadcrumb titulo="HISTÓRICO DE CAMPANHAS"
						:items="breadcrumb"
						class="breadcrumb" />
		</b-row>
		<b-row v-if="campanha || getCampanhaDetalhadaIsLoading" class="mx-0 my-3">
			<b-col v-if="getCampanhaDetalhadaIsLoading" class="text-center text-secondary font-weight-bold mt-3">
				<b-spinner variant="secondary" small class="mr-1"></b-spinner>
				Processando solicitação...
			</b-col>
			<b-col v-else class="relatorio-container py-3 px-4">
				<b-row>
					<b-col>
						<b-button v-if="campanhas" size="sm" variant="outline-secondary" class="rounded-0 px-3 py-1 mr-3" @click="clearCampanha">
							Voltar
						</b-button>
						<b v-if="campanha.Identificacao" class="text-secondary mr-2">
							{{campanha.Identificacao}}
							<span class="ml-2">-</span>
						</b>
						<span class="text-secondary mr-2">
							{{moment(campanha.Inclusao).format("DD/MM/YYYY / HH:mm:ss")}}
						</span>
						<span class="text-secondary">
							<i :class="`${campanhaStatus.filter(item => item.description == campanha.Status)[0]?.icon} mr-2`"></i>
							{{campanha.Status}}
						</span>
					</b-col>
					<b-col v-if="!campanhas" cols="12" md="auto">
						<b-button size="sm" variant="outline-info" class="rounded-0 px-3 py-1 mr-3" @click="clearCampanha">
							<i class="fas fa-bullhorn mr-2"></i>
							Listar histórico de campanhas
						</b-button>
					</b-col>
					<b-col cols="12" md="auto">
						<b-button size="sm"
								  class="rounded-0 px-4 py-1 box-cliente"
								  @click="exportCampanha"
								  :disabled="exportCampanhaIsLoading">
							<span v-if="exportCampanhaIsLoading">
								<b-spinner small class="ml-0 mr-2"></b-spinner>
								Exportando...
							</span>
							<span v-else>Exportar</span>
						</b-button>
					</b-col>
				</b-row>
				<b-row>
					<b-col class="relatorio-data-container app-scroll-custom m-3 p-2">
						<b-card no-body class="rounded-0">
							<b-card-header class="p-0">
								<b-button block
										  v-b-toggle.informacoes-detalhadas
										  variant="outline-secondary"
										  class="text-left rounded-0">
									<i class="fas fa-bullhorn mr-2"></i>
									Informações da campanha
								</b-button>
							</b-card-header>
							<b-collapse id="informacoes-detalhadas" visible>
								<b-card-body>
									<b-container fluid class="m-0 p-0">
										<b-row>
											<b-col cols="6" class="pr-5">
												<b-card-text v-if="campanha.Identificacao && campanha.Identificacao.trim()">
													<b class="text-cliente">Identificação:</b>
													<b> {{campanha.Identificacao}}</b>
												</b-card-text>
												<b-card-text>
													<b class="text-cliente">Template:</b>
													<br />
													<div v-html="formatarTemplate(campanha)"></div>
												</b-card-text>
												<b-card-text v-if="campanha.Parametros && campanha.Parametros.length > 0">
													<b class="text-cliente">Parâmetros:</b>
													<br />
													<ul class="pl-3">
														<li v-for="parametro in campanha.Parametros">
															<code class="font-weight-bold">{{parametro}}</code>
														</li>
													</ul>
												</b-card-text>
												<b-card-text>
													<b class="text-cliente">Tipo da Mensagem:</b>
													{{campanha.TipoMensagem}}
												</b-card-text>
												<b-card-text v-if="campanha.UrlArquivo && campanha.NomeArquivo && campanha.UrlArquivo.trim() && campanha.NomeArquivo.trim()">
													<b class="text-cliente">Arquivo: </b>
													<a :href="campanha.UrlArquivo" :title="campanha.UrlArquivo" target="_blank">
														{{campanha.NomeArquivo}}
													</a>
													<span></span>
												</b-card-text>
												<b-card-text v-else-if="campanha.UrlArquivo && campanha.UrlArquivo.trim()">
													<b class="text-cliente">URL do Arquivo: </b>
													<br />
													<a :href="campanha.UrlArquivo" target="_blank">
														{{campanha.UrlArquivo}}
													</a>
													<span></span>
												</b-card-text>
												<b-card-text v-if="campanha.Metadados && Object.keys(campanha.Metadados).length > 0">
													<b class="text-cliente">Metadados:</b>
													<br />
													<ul class="pl-3">
														<li v-for="key in Object.keys(campanha.Metadados)">
															<b>{{key}}:</b>
															{{campanha.Metadados[key]}}
														</li>
													</ul>
												</b-card-text>
											</b-col>
											<b-col colas="3">
												<b-card-text>
													<b class="text-cliente">Validade:</b>
													{{moment(campanha.Validade).format("DD/MM/YYYY / HH:mm")}}
												</b-card-text>
												<b-card-text>
													<b class="text-cliente">Disparo Forçado?</b>
													{{campanha.DisparoIrrestrito ? "Sim" : "Não"}}
												</b-card-text>
												<b-card-text>
													<b class="text-cliente">Setor:</b>
													{{campanha.Setor}}
												</b-card-text>
												<b-card-text>
													<b class="text-cliente">Canal:</b>
													{{campanha.Canal}}
												</b-card-text>
												<b-card-text>
													<b class="text-cliente">Destinatários (Total):</b>
													{{campanha.QuantidadeTotal}}
												</b-card-text>
												<b-card-text>
													<b class="text-cliente">Enviados:</b>
													{{campanha.QuantidadeEnviado}}
												</b-card-text>
												<b-card-text>
													<b class="text-cliente">Com Erro:</b>
													{{campanha.QuantidadeErro}}
												</b-card-text>
												<b-card-text>
													<b class="text-cliente">Entregues:</b>
													{{campanha.QuantidadeEntregue}}
												</b-card-text>
												<b-card-text>
													<b class="text-cliente">Lidos:</b>
													{{campanha.QuantidadeLido}}
												</b-card-text>
												<b-card-text>
													<b class="text-cliente">Respondidos:</b>
													{{campanha.QuantidadeRespondido}}
												</b-card-text>
											</b-col>
											<b-col cols="3">
												<b-card-text>
													<b class="text-cliente">Usuário:</b>
													{{campanha.Usuario}}
												</b-card-text>
												<b-card-text>
													<b class="text-cliente">Status:</b>
													<i :class="`${campanhaStatus.filter(item => item.description == campanha.Status)[0]?.icon} ml-2 mr-1`"></i>
													{{campanha.Status}}
												</b-card-text>
												<b-card-text>
													<b class="text-cliente">Data e Hora:</b>
													{{moment(campanha.Inclusao).format("DD/MM/YYYY / HH:mm:ss")}}
												</b-card-text>
											</b-col>
										</b-row>
									</b-container>
								</b-card-body>
							</b-collapse>
						</b-card>
						<b-card no-body class="rounded-0">
							<b-card-header class="p-0">
								<b-button block
										  v-b-toggle.campanha-logs
										  variant="outline-secondary"
										  class="text-left rounded-0">
									<i class="fas fa-code mr-2"></i>
									Logs ({{campanha.Logs?.length}})
								</b-button>
							</b-card-header>
							<b-collapse id="campanha-logs">
								<b-card-body>
									<b-table-simple hover small striped class="mb-0">
										<b-thead>
											<b-tr>
												<b-th>Mensagem do Log</b-th>
												<b-th>Data e Hora</b-th>
												<b-th>Status</b-th>
											</b-tr>
										</b-thead>
										<b-tbody>
											<b-tr v-for="log in campanha.Logs">
												<b-td>{{log.Mensagem}}</b-td>
												<b-td>{{moment(log.Inclusao).format("DD/MM/YYYY / HH:mm:ss")}}</b-td>
												<b-td>
													<i :class="`${campanhaStatus.filter(item => item.description == log.Status)[0]?.icon} mr-2`"></i>
													{{log.Status}}
												</b-td>
											</b-tr>
										</b-tbody>
									</b-table-simple>
									<div v-if="campanha.Logs?.length == 0" class="text-center m-2">
										Não existem logs registrados no sistema para esta campanha.
									</div>
								</b-card-body>
							</b-collapse>
						</b-card>
						<b-card no-body class="rounded-0">
							<b-card-header class="p-0">
								<b-button block
										  v-b-toggle.destinatarios
										  variant="outline-secondary"
										  class="text-left rounded-0">
									<i class="fas fa-users mr-2"></i>
									Destinatários
									<span v-if="destinatarios">({{destinatariosPaging.totalRows}})</span>
									<b-spinner v-else variant="secondary" small class="float-right mt-1"></b-spinner>
								</b-button>
							</b-card-header>
							<b-collapse id="destinatarios" visible>
								<b-card-body class="p-1">
									<b-row align-h="end" class="mx-0 my-2">
										<b-col cols="12" md="auto" class="py-1">
											<b>Filtros:</b>
										</b-col>
										<b-col cols="3" class="data-field insider-icon mx-2 p-0">
											<i class="fas fa-search"></i>
											<input type="text" placeholder="Buscar por nome, documento, destino..." v-model="destinatariosFilters.query" />
										</b-col>
										<b-col cols="2" class="data-field insider-icon mx-2 p-0">
											<i :class="`${destinatariosFilters.status?.icon ?? 'fas fa-dice-d6'} fa-fw`"></i>
											<select v-model="destinatariosFilters.status">
												<option :value="null">Todos os status</option>
												<option v-for="status in destinatarioStatus" :value="status">{{status.description}}</option>
											</select>
										</b-col>
										<b-col cols="12" md="auto">
											<b-button class="box-cliente rounded-0 py-1"
													  @click="destinatariosPaging.current = 1; getListaDestinatarios()">
												Filtrar
											</b-button>
										</b-col>
									</b-row>
									<b-table striped
											 hover
											 :fields="destinatariosFields"
											 :items="destinatarios"
											 class="mb-0">
										<template #cell(Info)="scope">
											<i v-if="scope.item.AtendimentoId"
											   class="fas fa-info-circle fa-lg cursor-pointer"
											   title="Ver detalhes"
											   @click="abrirInfoAtendimento(scope)"></i>
										</template>
										<template #cell(AtendimentoProtocolo)="scope">
											<b v-if="scope.value">#{{scope.value}}</b>
										</template>
										<template #cell(Metadados)="scope">
											<ul v-if="scope.value" class="pl-3">
												<li v-for="key in Object.keys(scope.value)">
													<b v-if="dadosCliente[key]">{{dadosCliente[key]}}:</b>
													<b v-else>{{key.slice(1)}}:</b>
													{{scope.value[key]}}
												</li>
											</ul>
										</template>
										<template #cell(DataStatus)="scope">
											<span v-if="scope.value">
												{{moment(scope.value).format("DD/MM/YYYY / HH:mm:ss")}}
											</span>
										</template>
										<template #cell(Status)="scope">
											<i :class="`${destinatarioStatus.filter(item => item.description == scope.value)[0]?.icon} mr-2`"></i>
											{{scope.value}}
										</template>
										<template #cell(Logs)="scope">
											<i class="fas fa-code fa-md cursor-pointer"
											   title="Ver logs"
											   @click="getLogsDestinatario(scope)"></i>
										</template>
										<template #row-details="scope">
											<b-card class="rounded-0">
												<div v-if="!scope.item.Logs" class="text-center font-weight-bold m-2">
													<b-spinner variant="secondary" small class="mr-1"></b-spinner>
													Carregando...
												</div>
												<b-table-simple v-else hover small striped class="mb-0">
													<b-thead>
														<b-tr>
															<b-th>Mensagem do Log</b-th>
															<b-th>Data e Hora</b-th>
															<b-th>Status</b-th>
														</b-tr>
													</b-thead>
													<b-tbody>
														<b-tr v-for="log in scope.item.Logs">
															<b-td>{{log.Mensagem}}</b-td>
															<b-td>{{moment(log.Inclusao).format("DD/MM/YYYY / HH:mm:ss")}}</b-td>
															<b-td>
																<i :class="`${destinatarioStatus.filter(item => item.description == log.Status)[0]?.icon} mr-2`"></i>
																{{log.Status}}
															</b-td>
														</b-tr>
													</b-tbody>
												</b-table-simple>
												<div v-if="scope.item.Logs?.length == 0" class="text-center m-2">
													Não existem logs registrados no sistema para este destinatário.
												</div>
											</b-card>
										</template>
									</b-table>
									<div v-if="destinatarios && destinatarios.length == 0" class="text-center m-2">
										Nenhum destinatário encontrado.
									</div>
									<div v-if="!destinatarios" class="text-center font-weight-bold m-2">
										<b-spinner variant="secondary" small class="mr-1"></b-spinner>
										Carregando...
									</div>
									<b-container v-if="destinatariosPaging.last" fluid class="paging my-3">
										<b-row align-h="center">
											<b-col cols="12" md="auto" class="p-1">
												<i v-if="destinatariosPaging.current > 1"
												   class="fas fa-angle-left cursor-pointer"
												   @click="destinatariosPaging.current--"></i>
												<i v-else class="fas fa-angle-left"></i>
											</b-col>
											<b-col cols="12" md="auto" class="p-1">
												{{destinatariosPaging.current}} / {{destinatariosPaging.last}}
											</b-col>
											<b-col cols="12" md="auto" class="p-1">
												<i v-if="destinatariosPaging.current < destinatariosPaging.last"
												   class="fas fa-angle-right cursor-pointer"
												   @click="destinatariosPaging.current++"></i>
												<i v-else class="fas fa-angle-right"></i>
											</b-col>
										</b-row>
									</b-container>
								</b-card-body>
							</b-collapse>
						</b-card>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
		<b-row v-else class="mx-0 my-3">
			<b-col class="relatorio-container py-3 px-4">
				<b-row align-h="between">
					<b-col cols="12" md="auto">
						<input type="date" v-model="dataInicio" class="mr-3" />
						<input type="date" v-model="dataFim" class="mr-3" />
						<select v-model="status" class="mr-3">
							<option :value="null">Status</option>
							<option v-for="status in campanhaStatus" :value="status.value">{{status.description}}</option>
						</select>
						<select v-model="setor" class="mr-3">
							<option :value="null">Setor</option>
							<option v-for="item in setores" :value="item.Id">{{item.Nome}}</option>
						</select>
						<b-button size="sm" class="rounded-0 px-4 py-1 box-cliente" @click="searchCampanhas">Filtrar</b-button>
					</b-col>
					<b-col>
						<b-button size="sm"
								  class="rounded-0 px-4 py-1 box-cliente float-right"
								  @click="exportCampanhas"
								  :disabled="exportCampanhasIsLoading">
							<span v-if="exportCampanhasIsLoading">
								<b-spinner small class="ml-0 mr-2"></b-spinner>
								Exportando...
							</span>
							<span v-else>Exportar</span>
						</b-button>
					</b-col>
				</b-row>
				<b-row>
					<b-col class="relatorio-data-container app-scroll-custom m-3 px-0">
						<b-table v-if="campanhas || searchCampanhasIsLoading"
								 id="tabela-campanhas"
								 striped
								 hover
								 :fields="campanhasFields"
								 :items="campanhas"
								 class="mb-0">
							<template #head(Actions)>
								<span></span>
							</template>
							<template #cell(Actions)="scope">
								<i v-if="scope.item.Status != 'Processando'" class="fas fa-file-alt fa-lg cursor-pointer" title="Ver detalhes" @click="verCampanha(scope.item.Id)"></i>
							</template>
							<template #cell(Identificacao)="scope">
								<b>{{scope.value}}</b>
							</template>
							<template #cell(Validade)="scope">
								{{moment(scope.value).format("DD/MM/YYYY / HH:mm")}}
							</template>
							<template #cell(DisparoIrrestrito)="scope">
								{{scope.value ? 'Sim' : 'Não'}}
							</template>
							<template #cell(Inclusao)="scope">
								{{moment(scope.value).format("DD/MM/YYYY / HH:mm:ss")}}
							</template>
							<template #cell(Status)="scope">
								<i :class="`${campanhaStatus.filter(item => item.description == scope.value)[0]?.icon} mr-2`"></i>
								{{scope.value}}
							</template>
							<template #cell(Totais)="scope">
								<div class="campanha-totais-container">
									<div v-if="['Erro', 'Concluído'].includes(scope.item.Status) && !getCampanhaLoaders.includes(scope.item.Id)">
										<div>
											<b class="text-cliente">{{scope.item.QuantidadeTotal}} destinatários</b>
										</div>
										<div>
											<b>Enviados:</b>
											{{scope.item.QuantidadeEnviado}}
										</div>
										<div>
											<b>Com erro:</b>
											{{scope.item.QuantidadeErro}}
										</div>
										<div>
											<b>Entregues:</b>
											{{scope.item.QuantidadeEntregue}}
										</div>
										<div>
											<b>Lidos:</b>
											{{scope.item.QuantidadeLido}}
										</div>
										<div>
											<b>Respondidos:</b>
											{{scope.item.QuantidadeRespondido}}
										</div>
									</div>
									<div v-else-if="getCampanhaLoaders.includes(scope.item.Id)">
										<b-spinner small></b-spinner>
									</div>
									<div v-else class="campanha-task">
										<div class="campanha-progresso">
											<div class="campanha-progresso-registros" :style="{ width: `${(scope.item.QuantidadeTotal / scope.item.TamanhoDaFila) * 100}%` }"></div>
											<div class="campanha-progresso-sucesso" :style="{ width: `${(scope.item.QuantidadeProcessado / scope.item.TamanhoDaFila) * 100}%` }"></div>
										</div>
										<small class="mx-1">
											<i v-if="scope.item.Status == 'Processando'"
											   class="fas fa-pause mr-2 text-primary cursor-pointer"
											   title="Interromper..."
											   @click="interromperCampanha(scope.item.Id)"></i>
											<i v-else-if="scope.item.Status == 'Interrompido'"
											   class="fas fa-play mr-2 text-primary cursor-pointer"
											   title="Retomar..."
											   @click="retomarCampanha(scope.item.Id)"></i>
											<b>{{scope.item.QuantidadeProcessado}} / {{scope.item.QuantidadeTotal}} destinatários</b>
										</small>
									</div>
								</div>
							</template>
						</b-table>
						<div v-if="searchCampanhasIsLoading" class="text-center font-weight-bold m-2">
							<b-spinner variant="secondary" small class="mr-1"></b-spinner>
							Processando solicitação...
						</div>
						<div v-else-if="campanhas?.length == 0" class="text-center m-2">
							Não existem campanhas registradas no sistema com base nos filtros informados.
						</div>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
		<ModalConversaPadrao ref="modal-informacoes-atendimento"
							 :dadosConversa="infoAtendimentoSelecionado" />
	</b-container>
</template>
<script>
	import moment from "moment";
	import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
	import axios from "axios";
	import ModalConversaPadrao from '@/components/consultas/ModalCoversaPadrao';
	import CampanhaHub from "@/assets/js/hubs/CampanhaHub";

	export default {
		name: "ConsultaHistoricoCampanhas",
		components: {
			Breadcrumb,
			ModalConversaPadrao
		},
		data() {
			return {
				breadcrumb: [
					{
						id: "1",
						classe: "fas fa-search",
						texto: "Consultas",
						link: "/consultas",
						cor: "#259cd3"
					},
					{
						id: "2",
						classe: "fa fa-bullhorn",
						texto: "Histórico de Campanhas",
						link: "",
						cor: "#259cd3"
					}
				],
				campanhasFields: [
					{ key: "Actions", label: "Ações", class: "text-center" },
					{ key: "Identificacao", label: "Identificação", sortable: true },
					{ key: "Validade", label: "Validade", sortable: true, class: "text-nowrap" },
					{ key: "Setor", label: "Setor de recebimento", sortable: true },
					{ key: "Canal", label: "Canal de recebimento", sortable: true },
					{ key: "Usuario", label: "Usuário", sortable: true },
					{ key: "DisparoIrrestrito", label: "Disparo Forçado?", sortable: true },
					{ key: "Totais", label: "Totais", sortable: true },
					{ key: "Inclusao", label: "Data e Hora", sortable: true, class: "text-nowrap" },
					{ key: "Status", label: "Status", sortable: true, class: "text-nowrap" }
				],
				destinatariosFields: [
					{ key: "Info", label: "Informações", class: "text-center" },
					{ key: "AtendimentoProtocolo", label: "Protocolo", sortable: true },
					{ key: "Destino", label: "Destino", sortable: true },
					{ key: "ClienteNome", label: "Nome", sortable: true },
					{ key: "ClienteDocumento", label: "Documento", sortable: true },
					{ key: "ClienteTelefone", label: "Telefone", sortable: true },
					{ key: "Metadados", label: "Dados da Planilha" },
					{ key: "DataStatus", label: "Data e Hora", sortable: true, class: "text-nowrap" },
					{ key: "Status", label: "Status", sortable: true, class: "text-nowrap" },
					{ key: "Logs", label: "", class: "text-right" }
				],
				campanhaStatus: [
					{ value: -1, description: "Erro", icon: "fas fa-times-circle text-danger" },
					{ value: 0, description: "Criado", icon: "fas fa-plus-circle text-info" },
					{ value: 1, description: "Processando", icon: "fas fa-sync-alt fa-spin text-secondary" },
					{ value: 2, description: "Interrompido", icon: "fas fa-stop-circle text-warning" },
					{ value: 3, description: "Concluído", icon: "fas fa-check-circle text-success" },
					{ value: 4, description: "Cancelado", icon: "fas fa-times text-secondary" }
				],
				destinatarioStatus: [
					{ value: -1, description: "Erro", icon: "fas fa-times-circle text-danger" },
					{ value: 0, description: "Na Fila", icon: "fas fa-stream text-secondary" },
					{ value: 1, description: "Processando", icon: "fas fa-sync-alt fa-spin text-secondary" },
					{ value: 2, description: "Disparo Irrestrito", icon: "fas fa-flag text-danger" },
					{ value: 3, description: "Pendente", icon: "fas fa-clock text-secondary" },
					{ value: 4, description: "Enviado", icon: "fas fa-check text-secondary" },
					{ value: 5, description: "Entregue", icon: "fas fa-check-double text-secondary" },
					{ value: 6, description: "Lido", icon: "fas fa-check-double text-primary" },
					{ value: 7, description: "Respondido", icon: "fas fa-check-circle text-success" }
				],
				dadosCliente: {
					CnpjCpf: "CNPJ/CPF",
					RazaoSocial: "Nome/Razão Social",
					NomeFantasia: "Apelido/Nome Fantasia",
					NomeContato: "Nome de Contato",
					Telefone: "Telefone",
					Email: "E-mail",
					Municipio: "Município",
					UF: "UF"
				},
				dataInicio: moment().format("YYYY-MM-DD"),
				dataFim: moment().format("YYYY-MM-DD"),
				status: null,
				setor: null,
				setores: null,
				campanhas: null,
				campanha: null,
				destinatarios: null,
				infoAtendimentoSelecionado: {},
				searchCampanhasIsLoading: false,
				exportCampanhasIsLoading: false,
				exportCampanhaIsLoading: false,
				getCampanhaDetalhadaIsLoading: false,
				getCampanhaLoaders: [],
				destinatariosFilters: {
					query: null,
					status: null
				},
				destinatariosPaging: {
					current: 1,
					last: null,
					totalRows: null
				}
			};
		},
		watch: {
			"destinatariosPaging.current"() {
				this.getListaDestinatarios();
			}
		},
		methods: {
			searchCampanhas() {
				if (CampanhaHub.CampanhaConnection && CampanhaHub.CampanhaConnection.state != "Disconnected") this.campanhas?.forEach(item => {
					CampanhaHub.unwatchProgress(item.Id);
				});

				this.campanhas = null;
				const form = {
					dataInicio: this.dataInicio,
					dataFim: this.dataFim,
					status: this.status,
					setorId: this.setor
				};
				this.searchCampanhasIsLoading = true;
				axios.get("/api/campanhas", {
					params: Object.assign({}, ...Object.keys(form).filter(key => form[key]).map(key => ({ [key]: form[key] })))
				}).then(response => {
					this.campanhas = response.data;

					let watchProgress = () => {
						this.campanhas.forEach(item => {
							CampanhaHub.watchProgress(item.Id);
						});
					};

					if (!CampanhaHub.CampanhaConnection || CampanhaHub.CampanhaConnection.state == "Disconnected") {
						CampanhaHub.start().then(watchProgress);
					} else watchProgress();

					this.searchCampanhasIsLoading = false;
				}).catch(() => {
					this.campanhas = null;
					this.searchCampanhasIsLoading = false;
				});
			},
			exportCampanhas() {
				const form = {
					dataInicio: this.dataInicio,
					dataFim: this.dataFim,
					status: this.status,
					setorId: this.setor
				};
				this.exportCampanhasIsLoading = true;
				axios.get("api/campanhas/exportar-consulta", {
					responseType: "arraybuffer",
					params: Object.assign({}, ...Object.keys(form).filter(key => form[key]).map(key => ({ [key]: form[key] })))
				}).then(response => {
					const link = document.createElement("a");
					link.href = window.URL.createObjectURL(new Blob([response.data]));
					link.download = `historico-campanhas-${Date.now()}.xlsx`;
					link.click();
					link.remove();
					this.exportCampanhasIsLoading = false;
				}).catch(() => {
					this.$bvToast.toast(`Não foi possível exportar o histórico no momento.`, {
						title: "Algo deu errado...",
						variant: "danger",
						solid: true
					});
					this.exportCampanhasIsLoading = false;
				});
			},
			exportCampanha() {
				this.exportCampanhaIsLoading = true;
				axios.get(`api/campanhas/${this.campanha.Id}/exportar-campanha`, {
					responseType: "arraybuffer",
					params: Object.assign({}, ...[
						this.destinatariosFilters.query?.trim() ? { query: this.destinatariosFilters.query?.trim() } : null,
						this.destinatariosFilters.status ? { status: this.destinatariosFilters.status.value } : null
					])
				}).then(response => {
					const link = document.createElement("a");
					link.href = window.URL.createObjectURL(new Blob([response.data]));
					link.download = `${this.campanha.Identificacao ?? 'campanha'}-${Date.now()}.xlsx`;
					link.click();
					link.remove();
					this.exportCampanhaIsLoading = false;
				}).catch(() => {
					this.$bvToast.toast(`Não foi possível exportar o histórico no momento.`, {
						title: "Algo deu errado...",
						variant: "danger",
						solid: true
					});
					this.exportCampanhaIsLoading = false;
				});
			},
			getCampanhaDetalhada(id) {
				this.getCampanhaDetalhadaIsLoading = true;
				return axios.get(`/api/campanhas/${id}/detalhes`).then(response => {
					this.campanha = response.data;
					this.getCampanhaDetalhadaIsLoading = false;
					this.getListaDestinatarios();
				}).catch(() => {
					this.campanha = null;
					this.destinatarios = null;
					this.getCampanhaDetalhadaIsLoading = false;
					this.$bvToast.toast(`Não foi possível consultar detalhes da campanha no momento.`, {
						title: "Algo deu errado...",
						variant: "danger",
						solid: true
					});
				});
			},
			getListaDestinatarios() {
				this.destinatarios = null;
				if (!this.campanha) return;

				axios.get(
					`/api/campanhas/${this.campanha.Id}/destinatarios`,
					{
						params: Object.assign({
							page: this.destinatariosPaging.current
						}, ...[
							this.destinatariosFilters.query?.trim() ? { query: this.destinatariosFilters.query?.trim() } : null,
							this.destinatariosFilters.status ? { status: this.destinatariosFilters.status.value } : null
						])
					}
				).then(response => {
					this.destinatarios = response.data;
					let totalPages = response.headers["paging-last"];
					if (totalPages) this.destinatariosPaging.last = parseInt(totalPages);
					this.destinatariosPaging.totalRows = parseInt(response.headers["total-rows"]);
				}).catch(() => {
					this.destinatarios = [];
				});
			},
			formatarTemplate(campanha) {
				let template = campanha.Template;
				if (campanha.TipoMensagem != "Html") {
					template = template
						.replace(/\n/g, "<br />")
						.replace(/( \_?\~?)(\*)(.*?)(\*)/g, "$1<b>$3</b>")
						.replace(/( \*?\~?)(\_)(.*?)(\_)/g, "$1<i>$3</i>")
						.replace(/( \*?\_?)(\~)(.*?)(\~)/g, "$1<del>$3</del>");
				}
				if (campanha.Parametros && campanha.Parametros.length > 0) [...new Set(campanha.Parametros)].forEach(parametro => {
					template = template.replaceAll(parametro, `<code class="font-weight-bold">${parametro}</code>`);
				});
				return template;
			},
			abrirInfoAtendimento(scope) {
				this.infoAtendimentoSelecionado = {
					Id: scope.item.AtendimentoId,
					Protocolo: scope.item.AtendimentoProtocolo,
					ClienteId: scope.item.ClienteId
				};
				this.$refs["modal-informacoes-atendimento"].show();
			},
			getLogsDestinatario(scope) {
				if (!scope.detailsShowing) {
					axios.get(`/api/campanhas/destinatarios/${scope.item.Id}/logs`).then(response => {
						this.$set(scope.item, "Logs", response.data ?? []);
					}).catch(() => {
						this.$set(scope.item, "Logs", response.data ?? []);
					});
				}
				scope.toggleDetails();
			},
			clearCampanha() {
				this.campanha = null;
				this.destinatarios = null;
				this.destinatariosPaging.current = 1;
				this.destinatariosPaging.last = null;
				this.destinatariosPaging.totalRows = null;
				this.destinatariosFilters.query = null;
				this.destinatariosFilters.status = null;
				if (!this.campanhas) this.searchCampanhas();
				if (this.$route.params.campanhaId) this.$router.replace({ path: "/consulta-historico-campanhas" });
			},
			moment(date) {
				return moment(date);
			},
			verCampanha(id) {
				return this.getCampanhaDetalhada(id).then(() => {
					if (this.campanha && !this.$route.params.campanhaId) this.$router.replace({ path: `consulta-historico-campanhas/${id}` });
					else if (!this.campanha && this.$route.params.campanhaId) this.$router.replace({ path: "/consulta-historico-campanhas" });
				});
			},
			retomarCampanha(campanhaId) {
				CampanhaHub.retomarCampanha(campanhaId);
			},
			interromperCampanha(campanhaId) {
				CampanhaHub.interromperCampanha(campanhaId);
			}
		},
		created() {
			if (this.$route.params.campanhaId) this.verCampanha(this.$route.params.campanhaId).then(() => {
				if (!this.campanha) this.searchCampanhas();
			});
			else this.searchCampanhas();
			axios.get("api/setor/lista-basica").then(response => {
				this.setores = response.data;
			});
			CampanhaHub.onCampanhaStatus((campanhaId, status) => {
				let campanha = this.campanhas.find(campanha => campanha.Id == campanhaId);
				campanha.Status = status;
				this.getCampanhaLoaders.push(campanhaId);
				axios.get(`api/campanhas/${campanhaId}`).then(response => {
					campanha = response.data;
					this.getCampanhaLoaders.splice(this.getCampanhaLoaders.indexOf(campanhaId) - 1, 1);
				}).catch(() => {
					this.getCampanhaLoaders.splice(this.getCampanhaLoaders.indexOf(campanhaId) - 1, 1);
				});
			});
			CampanhaHub.onDestinatarioAdicionado((campanhaId, qntRestante) => {
				let campanha = this.campanhas.find(campanha => campanha.Id == campanhaId);
				campanha.QuantidadeTotal = campanha.TamanhoDaFila - qntRestante;
			});
			CampanhaHub.onDestinatarioComSucesso(campanhaId => {
				let campanha = this.campanhas.find(campanha => campanha.Id == campanhaId);
				campanha.QuantidadeProcessado++;
			});
			CampanhaHub.onDestinatarioComErro(campanhaId => {
				let campanha = this.campanhas.find(campanha => campanha.Id == campanhaId);
				campanha.QuantidadeProcessado++;
			});
		},
		destroyed() {
			CampanhaHub.stop();
		}
	}
</script>
<style scoped>
	.breadcrumb {
		background-color: #259cd3 !important;
	}

	.relatorio-container {
		background-color: var(--cinza-3);
		min-width: 690px;
		height: calc(100vh - 170px);
		animation: ease-in slide-left .3s;
	}

	.relatorio-data-container {
		height: calc(100vh - 250px);
		background-color: #fff;
		font-size: 14px;
		color: var(--cinza-5);
		cursor: default;
		animation: ease-in slide-left .5s;
	}

		.relatorio-data-container table#tabela-campanhas {
			color: var(--cinza-5);
			animation: ease-in slide-up .3s;
		}

	input, select {
		display: inline-block;
		background-color: #fff;
		border: 0;
		outline-style: none;
		font-size: 14px;
		padding: 3px 5px;
		color: var(--cinza-5);
	}

	select {
		padding: 6px 5px;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.campanha-totais-container {
		position: relative;
		width: 10vw;
		height: 130px;
		overflow: auto;
	}

	.campanha-task {
		position: relative;
		width: 100%;
	}

	.campanha-progresso {
		position: relative;
		width: 100%;
		height: 10px;
		border-radius: 5px;
		margin: 5px 0;
		background-color: #fff;
		overflow: hidden;
	}

		.campanha-progresso:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			box-shadow: inset 0 0 3px 0 #999;
			border-radius: 5px;
		}

		.campanha-progresso > div {
			width: 100%;
			height: 100%;
			position: absolute;
			display: table;
		}

			.campanha-progresso > div.campanha-progresso-registros {
				background-color: #d7effb;
			}

			.campanha-progresso > div.campanha-progresso-sucesso {
				background-color: #259cd3;
			}

	.paging {
		user-select: none;
		font-size: 18px;
		font-weight: bold;
		line-height: 20px;
	}

		.paging i.fas {
			transition: all ease-in-out .3s;
		}

			.paging i.fas:not(.cursor-pointer) {
				opacity: .5;
			}

	@keyframes slide-left {
		0% {
			opacity: 0;
			left: 30px;
		}

		70% {
			opacity: .7;
			left: 7px;
		}

		100% {
			opacity: 1;
			left: 0;
		}
	}

	@keyframes slide-up {
		0% {
			opacity: 0;
			margin-top: 20px;
		}

		70% {
			opacity: .7;
			margin-top: 7px;
		}

		100% {
			opacity: 1;
			margin-top: 0;
		}
	}
</style>